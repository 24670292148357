










import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        HomeAppBar: () => import('@/components/core/AppBar.vue'),
        HomeView: () => import('@/components/core/View.vue'),
        HomeFooter: () => import('@/components/core/Footer.vue'),
    }
})
export default class Base extends Vue {
    
}
